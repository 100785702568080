import Nuclear1 from '../Portfolio_Pictures/ToyNuclear.jpg'
import Nuclear2 from '../Portfolio_Pictures/ToyExplodedView.png'

const NuclearReactor = () => {
    return (  
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
                <div className="opaquebox">
                During the Physical Computing course, we developed a toy prototype called Nuclear Reactor, inspired by classic games like Buckaroo and Operation. The project was programmed in C for the ATMega328P microcontroller, focusing on real-time input processing and system state management. The main objective was to light up streetlights without triggering an overload that would lead to a city "explosion".

The prototype featured two key inputs: a potentiometer knob to simulate the addition of cooling water and a weight sensor at the base of the cooling tower to detect the insertion of ‘radioactive’ materials. The sensor data was processed to adjust the game state, monitored via an Analog-to-Digital Converter reading.
                </div>
                <img src={Nuclear1} alt="The toy nuclear reactor with houses inserted" />
                </section>
                <section className='contentBlock'>
                <img src={Nuclear2} alt="Exploded view of the toy, showing most components" />
                <div className="opaquebox">
                The program logic involved calculating the cumulative 'radioactive' load and checking against a safety threshold. If the threshold was exceeded, the microcontroller triggered an interrupt sequence that caused the streetlights to flash red and activated a delay timer before signaling the city’s ‘explosion’ through a servo mechanism that released the houses from their slots.

This project emphasized not only analog input handling but also the integration of external libraries for precise weight measurements and real-time feedback to ensure the gameplay was engaging and responsive.
                </div>
                </section>
            </div>
        </div>
    );
}
 
export default NuclearReactor;