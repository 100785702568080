import Bachelor1 from '../Portfolio_Pictures/Attachment.jpg'
import Bachelor2 from '../Portfolio_Pictures/Attachment_with_controller.jpg'
import Bachelor3 from '../Portfolio_Pictures/doublePOV.jpg'

const AirHockey = () => {
    return (  
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
                <div className="opaquebox">
                For our Bachelor's project, we developed an Augmented Virtuality Air Hockey game with multiplayer functionality to investigate the passive haptic feedback provided by a custom-built input device—a functional extension of the original Quest controller. The project was programmed in C# within the Unity engine, leveraging Photon 2 for real-time multiplayer connectivity and synchronization. 
                </div>
                <img src={Bachelor1} alt="Custom-made Attachment"/>
                </section>
                <section className='contentBlock'>
                <img src={Bachelor2} alt="Quest Controller with Custom-made Attachment" />
                <div className="opaquebox">
                The main focus was designing an immersive experience that maintained responsive and smooth interaction, mimicking a physical air hockey paddle. Our custom device was engineered to glide seamlessly over surfaces and deliver tactile feedback, which was central to the gameplay’s realism. Core components of the codebase included collision detection algorithms for precise paddle and puck interaction, physics simulations to handle speed and direction changes, and networked gameplay logic that ensured smooth state transitions and real-time updates across clients. 
                </div>
                </section>
                <section className='contentBlock'>
                <div className="opaquebox">
                Key aspects of the development included implementing server-client communication through Photon 2, synchronizing player movements and game states to prevent latency issues, and scripting C# routines for scorekeeping and match flow control. The game’s design aimed to make matches playable both on physical surfaces and in a purely virtual environment, although testing revealed that the presence of a tangible surface significantly enhanced the overall experience.

                This project underscored the importance of integrating hardware and software for a cohesive user experience, showcasing a blend of custom device input handling, real-time multiplayer coding, and immersive AR mechanics.  
                </div>
                <img src={Bachelor3} alt="POV from both players in-game" />
                </section>
            </div>
        </div>
    );
}
 
export default AirHockey;