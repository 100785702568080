import myPic1 from '../Portfolio_Pictures/HQcv.jpg'

const About = () => {
    return (
            <div class="about">
                <div class="aboutContent">
                    <section className='contentBlock'>
                <div class="opaquebox">
                    <p>
                       I hold a Bachelor's Degree in IT Product Development, which has equipped me with a strong foundation in software design and development. I’m eager to continue growing my skill set, particularly in programming and innovative product solutions. With practical experience in Agile methodologies such as Scrum, I excel in collaborative environments that prioritize iteration and continuous improvement. 
                       I am known for my keen attention to detail—whether in writing efficient, maintainable code or maintaining clear and effective communication coworkers. Over the course of my studies, I gained significant experience with object-oriented programming languages like C# and Java, and I have also built projects using JavaScript and Python.
                    </p>
           
                </div>
           <img src={myPic1} alt="CV picture" />
                </section>
                <div class="opaquebox">
                    <p>
                    Outside of work, I am an avid gamer who enjoys both video games and board games with friends, finding inspiration and strategic thinking in these experiences. During my university years, I contributed to the community by helping organize events and gatherings for my peers, nurturing connections that foster creativity and teamwork.
                     I’m also passionate about tabletop RPGs like Dungeons & Dragons, where I often engage in storytelling, world-building, and problem-solving—skills that translate into my professional life. In quieter moments, I love to unwind by reading books and comics, which helps balance my analytical and creative pursuits.
                     </p>
                 </div>
                 </div>
                 </div>
        );
}
 
export default About;