const Home = () => { 

    return ( 
        <div class="homeContent">
            <section classname='contentBlock'>
                <div className="opaquebox" >
                I'm Mark Sløk Tietze, a passionate developer specializing in creating innovative digital experiences. Dive into my portfolio to explore projects that merge creativity, technology, and problem-solving. 
                Let's build something great together! Below are some of my highlighted skills.
                </div>
            </section>
            <div class="skills-container">
                <span class="skill-badge">JavaScript</span>
                <span class="skill-badge">React</span>
                <span class="skill-badge">CSS</span>
                <span class="skill-badge">Go</span>
                <span class="skill-badge">UI/UX Design</span>
                <span class="skill-badge">Scrum</span>
                <span class="skill-badge">C#</span>
                <span class="skill-badge">Java</span>
            </div>
        </div>
     );
}
 
export default Home;