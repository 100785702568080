import Starsign1 from '../Portfolio_Pictures/StarsignDark.jpg'
import Starsign2 from '../Portfolio_Pictures/MiniatureStarsign.jpg'
import Starsign3 from '../Portfolio_Pictures/Starsign gif.gif'

const Starsign = () => {
    return ( 
        <div className="about">
            <div className="aboutContent">
                <section className='contentBlock'>
            <div className="opaquebox">
            Starsign was a prototype developed for our initial project-based course, Foundations of IT Product Design. The goal was to create an interactive lamp that could function in unique and engaging environments. The core of the project was powered by an Arduino, using pseudo C programming to manage input and output logic.

The lamp's main feature was capacitive touch controls, which allowed users to interact with a miniature version of the lamp—a star-shaped model. This model served as an intuitive interface for selecting which sections of the larger lamp to illuminate. Each touch-sensitive region of the miniature star corresponded to a specific section of the main lamp, enabling a seamless user experience.
            </div>
            <img src={Starsign1} alt="Starsign in the Dark"/>
            </section>
            <section className='contentBlock'>
            <img src={Starsign2} alt="The controlling part of the lamp - a miniature version of it with capacative touch." />
            <div className="opaquebox">
            The underlying code managed the capacitive touch sensors, handling user input and triggering the corresponding LED outputs. We programmed conditional statements to map the input from the sensors to the correct output channels, ensuring real-time feedback. The miniature star itself was embedded with LEDs that lit up as a visual indicator when it was active, reinforcing user interaction cues.

Key aspects of the development included setting up the capacitive touch library, calibrating sensor sensitivity to minimize noise, and managing the state of the LEDs to synchronize the miniature model and the actual lamp. The project combined hardware interfacing with responsive code design to create an interactive, hands-on experience
            </div>
            </section>
            <img src={Starsign3} alt="Brief gif showing the capacative touch of the miniature lamp." class="center"/>
        </div>
        </div>
     );
}
 
export default Starsign;